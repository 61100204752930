<template>
  <v-container fluid class="job-teams">
    <v-container v-if="!isVisitDetail">
      <v-layout class="justify-end">
        <div class="mr-2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="teamLoading"
            color="cyan"
            @keydown.enter="getEngineerList"
            @keydown.tab="getEngineerList"
          ></v-text-field>
        </div>
        <div v-if="false" class="mx-2">
          <v-autocomplete
            v-model.trim="filter.visit"
            hide-details
            :items="visitList"
            clearable
            dense
            flat
            filled
            label="Visit"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="teamLoading"
            item-text="barcode"
            item-value="id"
            v-on:change="getEngineerList"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Visit(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                {{ formatTime(item.finished_at) }})
              </p>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                  >{{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                  {{ formatTime(item.finished_at) }})</v-list-item-title
                >
                <v-list-item-subtitle
                  class="font-size-14 pl-2 text-ellipsis pb-1"
                  >{{ item.title }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <div v-if="false" class="mx-2">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Visit Date Range"
                hide-details
                solo
                :disabled="teamLoading"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-on:click:clear="clearFilter('dates')"
                clearable
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="teamLoading"
              v-on:change="getEngineerList"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="ml-2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="teamLoading"
                v-on:click="getEngineerList"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ml-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="teamLoading"
                v-on:click="getEngineerList"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Reload</span>
          </v-tooltip>
        </div>
      </v-layout>
    </v-container>
    <v-skeleton-loader
      type="text@5"
      class="custom-skeleton table-rows-text"
      v-if="teamLoading"
    ></v-skeleton-loader>
    <v-simple-table
      v-else
      class="inner-simple-table"
      :class="{ 'custom-border-top': !isVisitDetail }"
      fixed-header
    >
      <template v-slot:default>
        <thead>
          <tr class="custom-border-bottom grey lighten-4">
            <td
              class="font-size-16 font-weight-500 text-uppercase"
              style="color: #24326d !important"
              width="100"
            >
              #
            </td>
            <td
              class="font-size-16 font-weight-500 text-uppercase"
              style="color: #24326d !important"
              width="50"
            ></td>
            <td
              class="font-size-16 font-weight-500 text-uppercase"
              style="color: #24326d !important"
            >
              Driver
            </td>
            <td
              class="font-size-16 font-weight-500 text-uppercase"
              style="color: #24326d !important"
              v-if="!isVisitDetail"
            >
              Delivery
            </td>
            <td
              class="font-size-16 font-weight-500 text-uppercase"
              style="color: #24326d !important"
              width="100"
            ></td>
            <td
              class="font-size-16 font-weight-500 text-uppercase"
              style="color: #24326d !important"
              width="100"
            >
              Status
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-if="lodash.isEmpty(teams) === false">
            <tr v-for="(data, index) in teams" :key="index">
              <td>
                <Barcode :barcode="data.engineer.barcode"></Barcode>
              </td>
              <td>
                <v-list-item-avatar size="50">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-bind="attrs"
                        v-on="on"
                        :lazy-src="$defaultProfileImage"
                        :src="$assetAPIURL(data.engineer.profile_logo)"
                        aspect-ratio="1"
                        class="margin-auto white--background"
                        transition="fade-transition"
                      ></v-img>
                    </template>
                    <span>{{ data.engineer.display_name }} </span>
                  </v-tooltip>
                </v-list-item-avatar>
              </td>
              <td class="py-2">
                <v-list-item-title
                  class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                  v-html="data.engineer.display_name"
                ></v-list-item-title>
                <v-list-item-subtitle
                  class="font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary"
                  v-html="data.engineer.primary_email"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  class="font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary"
                  v-html="data.engineer.primary_phone"
                ></v-list-item-subtitle>
              </td>
              <td v-if="!isVisitDetail">
                <template v-if="lodash.isEmpty(data.visit) === false">
                  <Barcode :barcode="data.visit.barcode"></Barcode>
                  <p
                    class="m-0 custom-nowrap-ellipsis font-size-16 font-weight-500"
                  >
                    {{ formatDate(data.visit.started_at) }}
                    {{ formatDateTimeStart(data.visit.started_at) }} -
                    {{ formatetimedata(data.visit.finished_at) }}
                  </p>
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <template
                  v-if="data.visit.status != 4 && data.visit.status != 2"
                >
                  <v-btn icon large v-on:click="deleteEngineerConfirm(data)">
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </td>
              <td class="py-2">
                <template v-if="data.engineer_active == 1">
                  <span
                    class="font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary"
                    >Assign</span
                  >
                </template>
                <template v-else>
                  <span
                    class="font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary"
                    >Unassign</span
                  >
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="isVisitDetail ? 4 : 5">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  Sorry! No Technician(s) Found.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <EngineerDelete
      :deleteDialog="engineerDeleteDialog"
      :requestUrl="engineerDeleteRequestURL"
      v-on:delete:success="engineerDeleteSuccess"
      v-on:delete:close="engineerDeleteDialog = false"
    ></EngineerDelete>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import EngineerDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
    visitId: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      engineerDeleteDialog: false,
      engineerDeleteRequestURL: "",
      dates: [],
      datePicker: false,
      job: 0,
      teams: [],
      visitList: [],
      filter: {
        search: null,
        visit: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      teamLoading: false,
    };
  },
  components: {
    Barcode,
    EngineerDelete,
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  methods: {
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    deleteEngineerConfirm(data) {
      const _this = this;
      _this.engineerDeleteRequestURL =
        "job/" + data.ticket + "/engineer/" + data.engineer.id;
      _this.engineerDeleteDialog = true;
    },
    engineerDeleteSuccess() {
      this.engineerDeleteDialog = false;
      this.$emit("delete:engineer", true);
      this.getEngineerList();
    },
    getEngineerList() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        visit: _this.visitId,
        daterange: _this.dates,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      clearTimeout(_this.timeout);
      _this.teamLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getEngineers(_this.job, { filter })
          .then((response) => {
            _this.teams = response.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.teamLoading = false;
          });
      }, _this.timeoutLimit);
    },
    getVisitList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getVisitsForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getEngineerList();
    },
  },
  mounted() {
    this.getEngineerList();
    // this.getVisitList();
  },
};
</script>
