<template>
  <v-row>
    <v-col md="12">
      <table class="line-item-multiple-table" width="100%">
        <thead>
          <tr class="custom-border">
            <th class="text-left border-right-new" width="5%">Image</th>
            <th class="text-left border-right-new" width="30%">Item</th>
            <th class="text-left border-right-new" width="10%">
              Total Quantity
            </th>
            <th class="text-left border-right-new" width="10%">Rejected</th>
            <th class="text-left border-right-new" width="10%">Delivered</th>
            <th class="text-left border-right-new" width="10%">
              Balance to be Delivered
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="pendingLineIteams && pendingLineIteams.length > 0">
            <template v-for="(line_item, index) in pendingLineIteams">
              <tr
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left border-right-new" width="5%">
                  <v-avatar class="avatar-custom">
                    <v-img
                      v-if="line_item && line_item.product"
                      class="customer-image"
                      width="70"
                      height="70"
                      contain
                      :lazy-src="$defaultImage"
                      :src="getProductImage(line_item.product)"
                    >
                    </v-img>
                  </v-avatar>
                </td>
                <td valign="top" class="text-left border-right-new" width="30%">
                  <v-layout>
                    <v-flex md12>
                      <p class="m-0 p-2 custom-grey-border">
                        <template
                          v-if="
                            line_item &&
                            line_item.product &&
                            line_item.product.name
                          "
                          >{{ line_item.product.name }}</template
                        >
                        <template v-else
                          ><em class="text--secondary">No name</em></template
                        >
                      </p>
                      <p class="m-0 p-2 custom-grey-border">
                        <template
                          v-if="
                            line_item &&
                            line_item.product &&
                            line_item.product.description
                          "
                          >{{ line_item.product.description }}</template
                        >
                        <template v-else
                          ><em class="text--secondary"
                            >No description</em
                          ></template
                        >
                      </p>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            color="cyan"
                          >
                            {{ line_item.product.barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </td>
                <td valign="top" class="text-left border-right-new" width="10%">
                  <v-chip
                    label
                    color="black"
                    text-color=""
                    outlined
                    class="chip-custom-font"
                  >
                    {{ Number(line_item.required_qty) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="10%">
                  <v-chip
                    v-if="!isDelivered"
                    label
                    text-color=""
                    color="red"
                    outlined
                    class="chip-custom-font"
                  >
                    {{ line_item.rejected_qty }}
                  </v-chip>
                  <v-text-field
                    v-if="isDelivered"
                    label="Rejected Quantity"
                    dense
                    filled
                    solo
                    flat
                    type="number"
                    color="cyan"
                    v-model="line_item.rejected_qty"
                    v-on:keypress="(e) => decimalValue(e)"
                    v-on:keyup="calculateBalance(line_item, index)"
                  ></v-text-field>
                </td>
                <td valign="top" class="text-left border-right-new" width="10%">
                  <v-chip
                    label
                    text-color=""
                    outlined
                    class="chip-custom-font"
                    color="green"
                  >
                    {{ line_item.actual_deliverd }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="10%">
                  <v-chip
                    label
                    text-color=""
                    outlined
                    color="orange"
                    class="chip-custom-font"
                  >
                    {{ line_item.balance_to_be_deliverd_qty }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td :colspan="6" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no pending delivery at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-col>
    <Dialog :common-dialog="confirm_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <p class="font-weight-600 font-size-19">
              Are you sure, you want to
              <span class="font-weight-700">Reject</span>?
            </p>
            <!--    <p v-else class="font-weight-600 font-size-19">
                You are cancel a contract job, do you want to reverse consume
                service back to contract?
              </p> -->
            <label for="cancel-reason" class="font-weight-600 font-size-18"
              >Reason</label
            >
            <v-autocomplete
              dense
              color="cyan"
              filled
              id="payment-mode"
              :items="resonList"
              :disabled="pageLoading"
              :loading="pageLoading"
              v-model.trim="cancel_reason_type"
              label="Reason"
              solo
              flat
              item-color="cyan"
              item-text="text"
              item-value="value"
              hide-details
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Reason Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <label for="cancel-reason" class="font-weight-600 font-size-18"
              >Remark</label
            >
            <v-textarea
              id="cancel-reason"
              auto-grow
              dense
              filled
              color="cyan"
              placeholder="Enter Remark..."
              solo
              flat
              v-model="cancel_reason"
              row-height="25"
            />
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <template>
          <v-btn
            v-on:click="create_received_line('partial')"
            :disabled="pageLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
        <v-btn
          :disabled="pageLoading"
          v-on:click="(confirm_dialog = false), $emit('canceldata', true)"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="failedDialog" :dialog-width="dialogWidth">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:click="create_received_line"
        >
          <v-container class="px-10">
            <!--  <p class="font-weight-600 font-size-19 ml-2">
                            Are you sure, you want to
                            <span class="font-weight-700">Failed</span>?
                        </p> -->
            <!--    <p v-else class="font-weight-600 font-size-19">
                                You are cancel a contract job, do you want to reverse consume
                                service back to contract?
                            </p> -->
            <label
              for="cancel-reason"
              class="font-weight-600 font-size-18 ml-1 required"
              >Reason
            </label>
            <v-autocomplete
              dense
              color="cyan"
              filled
              id="payment-mode"
              :items="resonList"
              :disabled="pageLoading"
              style="margin-bottom: 8px !important"
              :rules="[validateRules.required(failed_reason_type, 'Reason')]"
              :loading="pageLoading"
              v-model.trim="failed_reason_type"
              label="Reason"
              solo
              flat
              item-color="cyan"
              item-text="text"
              item-value="value"
              hide-details
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Reason Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <label
              for="cancel-reason"
              class="font-weight-600 font-size-18 ml-1 required"
              >Remark
            </label>
            <v-textarea
              id="cancel-reason"
              auto-grow
              dense
              filled
              color="cyan"
              placeholder="Enter Remark..."
              v-on:keypress="(e) => manageLimit(e)"
              v-on:paste="(e) => onPaste(e)"
              solo
              flat
              v-model="failed_reason"
              row-height="20"
              style="margin-bottom: 5px !important"
              :rules="[validateRules.required(failed_reason, 'Remark')]"
            />
            <div md="12" class="text-right">
              {{ failed_reason ? failed_reason.length : 0 }}/100
            </div>
            <label
              for="cancel-reason"
              :class="
                typeDelivery == 'failed'
                  ? 'font-weight-600 font-size-18 ml-1'
                  : 'font-weight-600 font-size-18 ml-1 required'
              "
              >Signature</label
            >
            <div class="mb-1" id="signature-pad">
              <div
                ref="customer_signature_div"
                class="custom-border-grey-dashed signature-pad-wrap"
                style="min-width: 100%,min-height:450px"
              >
                <canvas
                  ref="customer_signature"
                  style="height: 120px !important"
                ></canvas>
              </div>
              <div style="position: relative">
                <v-btn
                  style="position: absolute; bottom: 0px; right: -14px"
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  v-on:click="customer_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div>
            </div>

            <label
              for="cancel-reason"
              class="font-weight-600 font-size-18 ml-1"
              v-if="false"
              >Attachments</label
            >
            <FileTemplate
              v-if="false"
              :attachments="failed_attachments"
              v-on:file:updated="updateFiles('failed', $event)"
              allowUpload
            ></FileTemplate>
          </v-container>
          <!--  -->
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          v-on:click="(failedDialog = false), $emit('canceldata', true)"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>

        <v-btn
          v-on:click="create_received_line(typeDelivery)"
          class="mx-2 custom-bold-button white--text"
          :disabled="!formValid || pageLoading"
          :loading="pageLoading"
          color="cyan"
        >
          Save
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

import { toSafeInteger, reduce, toNumber } from "lodash";
import {
  GET,
  POST,
  /* QUERY,
    PATCH,
    PUT, */
} from "@/core/services/store/request.module";

/* import QuantityInput from "@/view/components/QuantityInput.vue"; */

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    parentDetail: {
      type: Object,
      default: null,
    },
    ticketId: {
      type: Number,
      default: 0,
    },
    isDelivered: {
      type: Boolean,
      default: false,
    },
    isReceive: {
      type: Boolean,
      default: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    typeDelivery: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      confirmRejected: false,
      pendingLineIteams: [],
      pageLoading: false,
      failedDialog: false,
      customer_signature: null,
      failed_reason: null,
      failed_reason_type: null,
      confirm_dialog: false,
      formValid: false,
      failed_attachments: [],
      vAttachments: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
      resonList: [
        {
          value: "technical-issue",
          text: "Technical Issue",
        },
        /* {
                value: 'technical-issue',
                text: "Technical Issue",
                },
                {
                value: 'technical-issue',
                text: "Technical Issue",
                }, */
      ],
      cancel_reason_type: null,
      cancel_reason: null,
    };
  },
  watch: {
    isReceive: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.getTotalDeliverd();
          if (this.confirmRejected) {
            this.create_received_line("partial");
          } /* else{
                        this.confirm_dialog = param;
                    } */
        }
      },
    },
    isFailed: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.failedDialog = param;
          this.initSignature();
          (this.customer_signature = null),
            (this.failed_reason = null),
            (this.failed_reason_type = null);
        } else {
          this.getPendingLineItem();
        }
      },
    },
    isDelivered: {
      deep: true,
      immediate: true,
      handler(param) {
        if (!param) {
          this.getPendingLineItem();
        }
      },
    },
  },

  methods: {
    updateFiles(type, param) {
      if (type == "failed") {
        this.failed_attachments = param;
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.failed_reason;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 99) {
        let trimValue = finalval.substring(0, 100);
        this.failed_reason = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.failed_reason && this.failed_reason.length > 99) {
        e.preventDefault();
      }
    },
    getTotalDeliverd() {
      let result = reduce(
        this.pendingLineIteams,
        (acc, row) => {
          acc = toNumber(acc) + toNumber(row.rejected_qty);
          return acc;
        },
        0
      );
      if (result == 0) {
        this.confirmRejected = true;
      }
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        ccanvas.setAttribute("width", 749);
        ccanvasDiv.setAttribute("style", "width:" + "749px");
        _this.customer_signature = new SignaturePad(ccanvas);
        /*    let cparentWidth = 800; 
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas); */
      });
    },

    getDelivered(item) {
      let required_qty = item.required_qty;
      let rejected_qty = item.rejected_qty;
      let delivered =
        item.rejected_qty > 0 ? Number(required_qty) - Number(rejected_qty) : 0;
      return delivered;
    },
    create_received_line(type) {
      /*     const filter_item = this.pendingLineIteams.filter((row) => {
                return row.rejected_qty;
            }); */
      if (!this.failed_reason_type) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Reason is required")
        );
        return false;
      }
      if (!this.failed_reason) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Remark is required")
        );
        return false;
      }
      if (
        this.customer_signature &&
        this.customer_signature._data &&
        this.customer_signature._data.length == 0 &&
        this.typeDelivery != "failed"
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Driver signature is required")
        );
        return false;
      }
      const _items = this.pendingLineIteams.map((row) => {
        return {
          item_id: row.line_item,
          product: row.product.id,
          quantity: row.rejected_qty ? row.rejected_qty : 0,
          required_qty: row.required_qty ? row.required_qty : 0,
          drivers: this.parentDetail.engineers,
        };
      });
      const payload = {
        delivery: toSafeInteger(this.parentDetail.id),
        customer: toSafeInteger(this.parentDetail.customer),
        line_items: _items,
        cancel_reason_type: this.cancel_reason_type,
        cancel_reason: this.cancel_reason,
        type: type,
        failed_reason_type: this.failed_reason_type
          ? this.failed_reason_type
          : null,
        failed_reason: this.failed_reason ? this.failed_reason : null,
        failed_attachment: this.failed_attachments,
        driver_signature: this.customer_signature
          ? this.customer_signature.toDataURL()
          : null,
      };
      this.pageLoading = true;
      this.$store
        .dispatch(POST, {
          url: "receive-line-item",
          data: payload,
        })
        .then(() => {
          this.getPendingLineItem();
          /* this.confirm_dialog = false; */
          this.failedDialog = false;
          this.$emit("updatedata", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getPendingLineItem() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, {
          url: "pending-line-item/" + this.parentDetail.id,
        })
        .then(({ data }) => {
          this.pendingLineIteams = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getProductImage(product_logo) {
      if (
        product_logo &&
        product_logo.product_image &&
        product_logo.product_image.file &&
        product_logo.product_image.file.url
      ) {
        return product_logo.product_image.file.url;
      }
      return null;
    },
    /*  quantityChcek(items,index){
             if(Number(items.delivered_quantity) > items.quantity){
                     this.selected_line_items[index].delivered_quantity =  Number(items.quantity); 
                 } 
            }, */
    decimalValue(e) {
      if (e && e.key && e.key == ".") {
        e.preventDefault();
      }
    },
    calculateBalance(items, index) {
      if (Number(items.rejected_qty) < 0) {
        this.pendingLineIteams[index].rejected_qty = 0;
      }
      if (
        Number(items.rejected_qty) > items.required_qty &&
        Number(items.balance_to_be_deliverd_qty) == 0
      ) {
        this.pendingLineIteams[index].rejected_qty = Number(items.required_qty);
      }
      if (
        Number(items.balance_to_be_deliverd_qty) > 0 &&
        Number(items.rejected_qty) > Number(items.balance_to_be_deliverd_qty)
      ) {
        this.pendingLineIteams[index].rejected_qty = Number(
          items.balance_to_be_deliverd_qty
        );
      }
    },
  },
  mounted() {
    if (this.parentDetail.id > 0) {
      this.getPendingLineItem();
    }
    this.initSignature();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 45);
    },
  },
  components: {
    /*  QuantityInput */
    Dialog,
    FileTemplate,
  },
};
</script>
